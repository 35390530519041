.spk_info_container{
  background: #FFFFFF;
  /* Stroke */

  border: 1px solid #E4E6EF;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 443px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 12px;
  margin-top: 10px;
}

.spk_info_title{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #4A5677;
  margin-left: 15px;
}
