.AreaFilter {
    i {
        margin-left: 0.8rem;
        transform: translate(0,1.8px);
        font-size: 17px;
        color: #B8BDD2;
    }
    .dropdown-group {
        position: relative;
        .dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            max-height: 300px;
            overflow: auto;
            z-index: 1;
        }
        .dropdown-option:hover {
            background: #f8f8f8;
        }
    }
}