.wrapper {
    transform-origin: top;
    background-color: white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: -8px;
    margin-top: -8px;
}

.t {
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background: white;
        border-radius: 6px;
    }
      
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 6px;
    }
      
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
        border-radius: 6px;
    }
}

.item {
    padding: 0 8px;
    height: 36px;
    width: 100%;
    background-color: white;
    color: #4A5677;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;

    &:hover {
        cursor: pointer;
        background-color: #F3F6F9;
    }

    + .item {
        margin-top: 3px;
    }
}

.item-disable {
    padding: 0 8px;
    height: 36px;
    width: 100%;
    background-color: #FFFFFF;
    color: #4A5677;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    cursor: default;

    + .item-disable {
        margin-top: 3px;
    }
}


.display {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    height: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    height: 40px;
    border: 1px solid #DBE3EF;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

.label {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    height: 16px;
    color: #4A5677;
    width: 100%;
    margin-left: 12px;
    margin-right: 25px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.icon {
    position: absolute;
    top: 10px;
    right: 4px;
    color: #4A5677;
}

.item-selected {
    padding: 0 8px;
    height: 36px;
    width: 100%;
    background-color: #3699FF;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    cursor: default;

    + .item-disable {
        margin-top: 3px;
    }
}

.item-selected-disable {
    padding: 0 8px;
    height: 36px;
    width: 100%;
    background-color: #3699FF;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    cursor: default;

    + .item-disable {
        margin-top: 3px;
    }
}