.DataTable {
    .table thead th, .table thead td {
        font-family: Inter;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;
        color: #9EA5BD;
        text-transform: uppercase;
        padding: 15px 13px 18px 13px;
        white-space: nowrap;

        &:first-child {
            padding-left: 19.5px;
        }

        &:last-child {
            padding-right: 19.5px;
        }
    }
    .table tbody th, .table tbody td {
        padding: 13px 13px 13px 13px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
            padding-left: 19.5px;
        }

        &:last-child {
            padding-right: 19.5px;
        }
    }

    .row-hover {
        &:hover{
            background-color: #F7F7F7 !important;
        }
    }
}