

.navBarButton {
  width: 38px;
  height: 38px;
}

.navBar{
  transition: top 0.7s;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 10000; 
  background-size: cover;
  height: 60px;
  @media (max-width: 992px) {
    display: none !important;
  }
}

.dropdown-toggle::after {
  display: none !important;
}
