@import "~perfect-scrollbar";
@import "../fontawesome/css/all.css";

$text : #4A5677;

html,
body {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.text-dark-50 {
  color: #7e8299 !important;
}

@media (min-width: 992px) {
  .aside-fixed .wrapper {
    padding-left: 265px !important;
  }
  
  #kt_header {
    margin-left: 265px;
    display: initial !important;
  }

  .aside-fixed.aside-minimize:not(.aside-minimize-hover) {
    .wrapper {
      padding-left: 70px !important;
    }
    #kt_header {
      margin-left: 70px;
    }
  }



  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    // -webkit-box-shadow: 0 0 0 30px white inset !important;
    // transition: background-color 5000s ease-in-out 0s;
  }

  .rdt_TableRow {
    padding: 0.8rem 0;
  }

  .dropdown {
    button {
      width: 100%;
    }
  }

  .dropdown-toggle {
    width: 100% !important;

    &::after {
      content: "\f107" !important;
      font-family: "Font Awesome 5 Pro" !important;
      font-size: 1rem !important;
    }
  }

  .dropdown-item {
    font-weight: 400 !important;
  }
}

.swal2-icon {
  margin: 2.5em auto 0.6em !important;
}

.q-pointer-event-none {
  pointer-events: none !important;
}

.q-max-line-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.q-max-line-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.q-max-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.q-max-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.q-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.q-hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.q-aspect-ratio-thumbnail {
  aspect-ratio: 16/10;
  object-fit: cover;
  overflow: hidden;
}

.q-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.duy-custom-input-image {
  max-width: 200px !important;
  max-height: 300px !important;
  border-radius: 10px;
}

.Title {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: $text;
}

.Bold_30 {
  font-family: Inter;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}
.Bold_24 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: $text;
}

.Bold_20 {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.Bold_18-700 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: $text;
}

.Bold_18-600 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: $text;
}

.Bold_14 {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}

.Bold_13 {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: $text;
}

.Regular_13 {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: $text;
}

.Normal_12 {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: $text;
}
@media(min-width: 992px) {
  .aside-fixed .aside {
    top: 0px;
    left: 0px;
    //bottom: 38px;
    bottom: 0px;
  }

  .app-header-container {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
  }

  .aside {
    border-radius: 0px;
  }
}

p { 
  margin: 0;
}

.PageBackground {
  margin: 0;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  background-color: linear-gradient(180deg, rgba(0, 15, 67, 0.7) 0%, rgba(0, 0, 0, 0) 112.07%) !important;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translate(-50%, 0%);
}

.translate-middle-y {
  transform: translate(0%, -50%);
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.left-50 {
  left: 50%;
}

.left-100 {
  left: 100%;
}

body.modal-open .modal .modal-dialog {
  width: inherit;
}

.modal-content {
  border-radius: 0.42rem !important;
}

.display-none {
  display: none;
}

.display-md-block {
  @media (min-width: 768px) {
    display: block !important;
  }
}

.w-md-fit-content {
  @media (min-width: 768px) {
    width: fit-content !important;
  }
}

.w-md-max-content {
  @media (min-width: 768px) {
    width: max-content !important;
  }
}

.display-smaller-md-none {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.display-bigger-md-none {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.table {
  td {
      white-space: normal !important;
  }
}

.overflow-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.word-break-all {
  word-break: break-all !important;
}

.w-xxxl-300px {
  @media (min-width: 1920px) {
    width: 300px !important;
  } 
}

.w-xxxl-350px {
  @media (min-width: 1920px) {
    width: 350px !important;
  } 
}

.px-smaller-md-0 {
  @media (max-width: 768px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.pl-md-0 {
  @media (min-width: 768px) {
    padding-left: 0 !important;
  }
}

.pr-md-0 {
  @media (min-width: 768px) {
    padding-right: 0 !important;
  }
}