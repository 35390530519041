.login-page {
  .input-field {
    height: 40px;
    /* border-radius: 55px; */
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    padding: 0;
    border: 1px solid #E8E9EB;
  }

  .input-field i {
    background: #E8E9EB;
  }

  input[type='text'],
  input[type='password'] {
     padding: 12px 16px;
    height: 100%;
    display: block;
    background: white;
    border: none;
    outline: none;
    width: 100%;
  }

  input#password {
    padding-right: 40px;
  }

  input:focus {
    outline: none;
  }

  /* input[type='checkbox'] {
      background: red;
      display: inline-block;
      width: 24px;
      height: 24px;
  } */


  label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #8A9099;
    margin-bottom: 10px;
  }

  input[type=checkbox] + label {
    margin: 0;
    margin-left: 8px;
  }

  .login-form-control {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
  }

  .login-form-control.login-checkbox {
    flex-direction: row;
    align-items: center;
  }

  .error {
    color: red;
  }

  .password-toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 100%;
    position: absolute;
    right: 0;
  }

}
