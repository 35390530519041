.container {
  position: relative;

  .placeholder_container {
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 18px;
    justify-content: space-between;
    padding: 12px 12px 12px 12px;
  }

  .dropdown_container {
    padding: 6px 0px 6px;
    background: #FFFFFF;
    border: 1px solid #E4E6EF;
    box-sizing: border-box;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    position: absolute;
    width: 100%;
    z-index: 100000;
    margin-bottom: 12px;

    .select_tree_container {
      max-height: 400px;
      overflow-y: auto;

      .item {
        width: 100%;
        height: 30px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        &:hover{
          background-color: #efefef;
        }
      }
    }
  }
}

