.FieldName {
    label {
        margin-bottom: 0.25rem;
        color: #9EA5BD;
    }
    input {
        color: #4A5677;
    }
}
// .modal-dialog {
//     width: 1000px;
//     margin: auto;
//   }
.modal-content {
    // width: 1000px;
    border-radius: 0;
    border: none;
    background-color: #fff;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 0;
}
.Card {
    background-color: #F7F9FC;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
}
.save {
    background-color: #2AB448 !important;
    color: #fff;
    border-radius: 0.25rem;
    width: 100%;
    cursor: pointer;

    &:hover {
        background-color: #3ACE5A  !important;
        opacity: 0.8 !important;
        color: #fff;
        border-radius: 0.25rem;
        cursor: pointer;
    }
    
}

.save-disabled{
    background-color: grey !important;
    color: #fff;
    border-radius: 0.25rem;
    width: 100%;
    cursor: pointer;
    pointer-events: none;
}

.checkbox-disabled{
    pointer-events: none;
}