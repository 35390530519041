.level-up {
  i {
    color: #3ACE5A;
    transform: rotate(90deg);
  }

  p {
    color: #3ACE5A !important;
  }
}

.sort-direction {
  p {
    position: relative;
    width: min-content;
  }

  i {
    position: absolute;
    left: calc(100% + 0.5rem);
  }
}

.bulletin-datatable {
  tbody {
    td:not(:last-child) {
      white-space: normal;
    }
  }


}

.bulletin-status-badge {
  border-radius: 4px;
  text-align: center;
  padding: 4px 12px;
  white-space: nowrap;

  &#{&}-approved {
    background: rgba(32, 161, 68, 0.1);
    color: #20A144;
  }

  &#{&}-waiting {
    background: rgba(81, 128, 251, 0.1);
    color: #5180FB;
  }

  &#{&}-created {
    background: rgba(149, 35, 117, 0.1);
    color: #952375;
  }

  &#{&}-canceled {
    background: rgba(229, 110, 25, 0.1);
    color: #E56E19;
  }

  &#{&}-rejected {
    background: rgba(246, 78, 96, 0.1);
    color: #F64E60;
  }

}
