.container {
  position: relative;

  .placeholder_container {
    height: 40px;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 18px;
    justify-content: space-between;
    padding: 12px 12px 12px 12px;
    border: 1px solid #DBE3EF;
  }

  .dropdown_container {
    padding: 12px 12px 0;
    background: #FFFFFF;
    border: 1px solid #E4E6EF;
    box-sizing: border-box;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    position: absolute;
    width: 100%;
    z-index: 99;
    margin-bottom: 12px;
    //@media (min-width: 768px) {
    //  width: calc(min(500px, 100vw));
    //}

    .select_tree_container {
      max-height: 400px;
      overflow-y: auto;
      margin: 10px -12px 0 -26px;

      .tree_item_container {
        padding-left: 26px;

        .tree_item {
          display: flex;
          align-items: center;
          margin-top: 16px;
          margin-bottom: 16px;

          .toggle_expand {
            cursor: pointer;
          }
        }
      }

    }
  }
}

