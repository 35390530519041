.imageDiv{
    // background-image: url("../../assets/images/img_radio_manager_background.png");
    background-size: cover;
    aspect-ratio:5.3 / 2;
}

.playButton {
    gap: 8px;
    height: 38px;
    background: #3ACE5A;
    border-radius: 8px;
    border-color: #2AB448;
    border: none;
    i {
        color: white;
        font-size: 17.44px;
    }
  
    p {
        line-height: 15.73px;
    }

    &:hover{
        background: #21b842;
    }
  }

.headerSquareButton{
    width: 38px;
    height : 38px;
    
}

.navBarStickey{
  
}

.header{
    position: static !important;
}

// #kt_header {
//     display: none;
//     i {
//         color: white;
//         font-size: 17.44px;
//     }
// }
