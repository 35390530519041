.FieldName {
    label {
        margin-bottom: 0.25rem;
        color: #9EA5BD;
    }
    input {
        color: #4A5677;
    }
}

.Card {
    background-color: #F7F9FC;
    border-radius: 0.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
}
.table-responsive{
    height:315px;  
    width: 100%;
    overflow:scroll;
  }
  .table-responsive::-webkit-scrollbar {
    display: none;
}
thead tr:nth-child(1) th{
    background: white;
    position: sticky;
    top: 0;
    z-index: 10;
}
body.modal-open .modal {
    display: flex !important;
    height: 100%;
} 

body.modal-open .modal .modal-dialog {
    margin: auto;
}

.save {
    background-color: #2AB448 !important;
    color: #fff;
    border-radius: 0.25rem;
    width: 100%;
    cursor: pointer;

    &:hover {
        background-color: #3ACE5A  !important;
        opacity: 0.8 !important;
        color: #fff;
        border-radius: 0.25rem;
        cursor: pointer;
    }

}