.button {
    background-color: #3e97ff;
    display: inline-block;
    height: 40px;
    border-radius: 12px;
    border: none;
    line-height: 15px;
    padding: 10px 0;
    color: white;
    margin-bottom: 3px;
    font-weight: 500;
    left: 50%;
    font-family: 'Inter';
    width: 40%;
    margin-left: 30%;
    margin-right: 30%;
}
.dot {
    height: 13px;
    width: 13px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
}
.button__1{
    background-color: white;
    border-radius: 8px;
    border: 1px solid #5181FB;
    box-shadow: 0px 3px 10px #DEDAEC;
    height: 40px;
    width: 70%;
    padding: 9px 0;
    margin: 5px auto;
}
.button__2{
    background-color: #F4F5F8;
    border: 4px;
    margin: 0 3px;
}
.rounded-circle {
    border-radius: 50%;
    width: 30%;
    height: 30%;
    background-color: #3e97ff;
    display: inline-block;
    margin-top: 15px;
    margin-left: 35%;
    margin-right: auto;
}

.Card{
    border: 1px solid black;
    padding: 5px;
    border-radius: 6px;
    position: flex;
}
.Card-body .Card-title{
    font-family: 'Inter';
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    align-items: center;
    margin-top: 10px;
    margin-left: 5px
}
.Card-body .Card-footer{
    position: absolute;
    bottom: 20px;
    width: 95%;
    height: 2.5rem; 
}
.span__1{
    color: #393874;
    font-weight: 500;
    font-size: 14px;
}
.span__2{
    color: #877F9B;
    font-weight: 500;
    font-size: 14px;
}
.span__3{
    color: #3ACE5A;
    font-weight: 600;
    font-size: 14px;
}
#circle{
    color: #3e97ff;
    // margin-left: 50px;
}

.carousel-inner{
    height: 100%;
}
.carousel-indicators{
    display: none;
}
.carousel-control-prev{
    display: none;
}
.carousel-control-next{
    display: none;
}

.non-mt{
    margin-top: 0px !important;
}

.daterangepicker {
    .drp-buttons {
        display: flex !important;
        justify-content: space-around !important;
        .cancelBtn{
            width: 30%;
        }
        .applyBtn {
            width: 30%;
        }
        .drp-selected{
            display: none;
        }
    }
    .ranges{
        display: none;
    }
}

.toggle-button {
    height: calc(1.5em + 1.3rem + 2px);
    background-color: #FFFFFF;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;

    &:focus {
        border-color: #69b3ff !important;
        -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}

.react-bootstrap-daterangepicker-container {
    width: 100% !important;
}