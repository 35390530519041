.AppPagination {
    .AppPagination_StepBox {
        position: relative;
        i {
            margin-left: 0.6rem;
            transform: translate(0,1.8px);
            color: #8A9099;
            font-weight: 300;
        }
        .dropdown {
            position: absolute;
            bottom: 100%;
            left: 0;
            div:hover {
                background: #f8f8f8;
            }
        }
    }  

    .AppPagination_Num {
        position: relative;
        width: 2.615rem;
        height: 2.615rem;
        cursor: pointer;
        p {
            position: absolute;
            top: 50%;
            left: 50%;
            font-weight: 400;
            transform: translate(-50%,-50%);
            color: #5C6077;
        }
    }
    .AppPagination_Control {
        position: relative;
        width: 2.615rem;
        height: 2.615rem;
        background: #E2F0FF;
        cursor: pointer;
        i {
            position: absolute;
            top: 50%;
            left: 50%;
            font-weight: 400;
            transform: translate(-50%,-50%);
            color: #5180FB;
        }
    }
    .AppPagination_Num, .AppPagination_Control{
        + .active {
            background: #5180FB;
            i, p {
                color: white;
            }
        }
        @media (hover: hover) {
            &:hover{
                background: #5180FB;
                i, p {
                    color: white;
                }
            }
        }
    }
    .disable {
        cursor: default !important;
        background: #f8f8f8 !important;
        i {
            color: #8A9099 !important;
        }
    }
    .AppPagination_3dots {
        position: relative;
        width: 2.615rem;
        height: 2.615rem;
        cursor: default;
        p {
            position: absolute;
            top: 50%;
            left: 50%;
            font-weight: 400;
            transform: translate(-50%,-50%);
            color: #5C6077;
        }
    }
}