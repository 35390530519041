.login-form {
  width: 350px;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 100%;
  padding: 16px;

  form {
    width: 100%;
  }

  .form-title {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 0px;
    text-align: center;
    color: #3f434a;
  }
}

.login-form-error {
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  font-weight: 500;
  margin-bottom: 16px;
}

.login-form-check {
  display: flex;
  justify-content: space-between;
  color: #304ffd;
  font-size: 14px;
}


