.labelInput{
    font-weight: 600;
    font-size: 13px;
    color : #4A5677;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    text-align: right;

    @media (min-width: 768px) and (max-width: 1200px), (max-width: 576px) {
        text-align: left;
        justify-content: start;
        margin-bottom: 5px;
    }
}

.inputField {
    padding-left: 0;

    @media (min-width: 768px) and (max-width: 1200px), (max-width: 576px) {
        padding: 0 12.5px;
    }
}

.switch {

}

.dropdownSelect{
    width: 70% !important;
}