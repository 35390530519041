notiIcon {
  width: 50px;
  height: 50px;
}

.notiDropdownMenuNavbar {
  inset: 0px -192px auto auto !important;
}

.notiDropdownMenuHeader {
  inset: 0px -38px auto auto !important;
}

.notiDropdown {
  // overflow-y: scroll;
  // max-height: 500px;
  // overflow-x: hidden;
  width : 400px;
}

.notiScroll{
  overflow-y: scroll;
  max-height: 500px;
  overflow-x: hidden;
}

.notificationText {
  max-height: 46px;
  font-size: 13px;
  white-space: normal;
  line-height: 15.5px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #4a5677;
  text-align: justify;
}

@media only screen and (max-width: 576px) {
  .notiDropdown {
      width: 300px;
  }

  .notiIcon {
    width: 45px;
    height: 45px;
  }
} 

.noti-button {
  z-index: 3;
  width: 50%;
  height : 30px;
  border-radius: 6px;
  border: none;
  line-height: 20px;
  padding: 10px 0;
  color: white;
  margin-bottom: 3px;
  font-weight: 500;
  &:hover{
      opacity: 0.9;
  }
  // &:disabled{
  //     background-color: #cccccc;
  // }
}

.notiScroll::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    opacity: 0.5;
    //background-color: #F5F5F5;
}

.notiScroll::-webkit-scrollbar
{
    width: 5px;
    opacity: 0.5;
    //background-color: #F5F5F5;
}

.notiScroll::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    opacity: 0.5;
    -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,.3);
    //background-color: #555;
}