.add-slash {
    &:after {
        display: inline-block;
        padding-left: 0.5rem;
        color: #4A5677;
        content: "/";
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
    }
    color: #5180FB;
}

.text-emphasis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.nowrap {
    white-space: nowrap;
}