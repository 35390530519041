.login-checkbox {
  input[type="checkbox"] {
    position: relative;
    width: 1.5em;
    height: 1.5em;
    border: 0;
    border-radius: 6px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    background-color: #EBEDF3;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 4px;
      left: 7px;
      width: 5px;
      height: 10px;
      border-style: solid;
      border-color: white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      opacity: 0;
    }
    &:hover {
      background-color: #ccc;
    }
    &:checked {
      color: white;
      border-color: #2196F3;
      background: #2196F3;
      &::before {
        opacity: 1;
      }
      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
    &:disabled {
      background: #ccc;
    }
  }
}