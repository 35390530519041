.AddDevice {
    .StationName, .AreaName {
        label {
            margin-bottom: 0.25rem;
            color: #9EA5BD;
        }
        input {
            color: #4A5677;
        }
    }
    .modal-header {
        padding: 1.25rem;
    }
}

.loading{
    opacity: 0.3;
}