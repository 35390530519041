.accountDropdownMenu {
  inset: 0px -140px auto auto  !important;
}

.dropdownButton {
  position: static !important;
  top: 50px;
  left: 30px;

  ::afer {
    position: static !important;
  }
  ::before {
    position: static !important;
  }
}

.dropdown-toggle.btn {
  ::after {
    position: static !important;
  }
  ::before {
    position: static !important;
  }
}

.dropdown-menu {
  inset: 0px -120px auto auto;
}
