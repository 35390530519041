.blocked-wrapper select {
  color: #e64135;
  font-weight: 500;
}

.active-wrapper select {
  color: #5180fb;
  font-weight: 500;
}

.radius-8-custom {
  border-radius: 8px !important;
}

.password-toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  // height: 100%;
  position: absolute;
  right: 0;
  :hover{
    cursor: pointer;
  }
}