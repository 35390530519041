.delete {

    .title {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        height: 48px;
        color: #4A5677;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #DBE3EF;
    }
    
    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #F6F7FB;
    }
    
    .icon-trash {
        color: #9EA5BD;
        font-size: 70px;
        border: 10px solid #F2F3F7;
        border-radius: 45px;
        padding: 20px;
        background-color: white;
        margin-top: 20px;
    }
    
    .confirm {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #4A5677;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
    .action {
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top: 1px solid #DBE3EF;
    }

    .btn {
        flex: 1;
        height: 40px;
        border-radius: 6px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
        border: 1px solid #DBE3EF;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #4A5677;
        margin-left: 20px;
        background-color: #F2F3F7;
    
        &:hover {
            opacity: 0.8 !important;
        }
    }

    .btn + .btn {
        margin-left: 16px;
        background-color: #E64135;
        border: 1px solid #CD1F13;
        color: white;
        margin-right: 20px;
    }
}

.add {

    .title {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        height: 48px;
        color: #4A5677;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #DBE3EF;
    }

    .body {
        display: flex;
        flex-direction: column;
        background-color: #F6F7FB;
    }

    .span {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #9EA5BD;
        margin-top: 16px;
        margin-left: 20px;
    }

    .border {
        width: calc(100% - 40px);
        height: 36px;
        border: 1px solid #DBE3EF;
        background-color: white;
        border-radius: 8px;
        overflow: hidden;
        margin-top: 4px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 16px;
    }

    .input {
        width: calc(100% - 2px);
        height: 34px;
        outline: none;
        border: none;
        padding-left: 16px;
        padding-right: 8px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
    }

    .action {
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top: 1px solid #DBE3EF;
    }

    .btn {
        flex: 1;
        height: 40px;
        border-radius: 6px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
        border: 1px solid #DBE3EF;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #4A5677;
        margin-left: 20px;
        background-color: #F2F3F7;
    
        &:hover {
            opacity: 0.8;
        }
    }

    .btn + .btn {
        margin-left: 16px;
        background-color: #3ACE5A;
        border: 1px solid #2AB448;
        color: white;
        margin-right: 20px;
    }
}

.edit {
    .btn + .btn {
        margin-left: 16px;
        background-color: #E64135;
        border: 1px solid #CD1F13;
        color: white;
        margin-right: 0px;
    }

    .btn + .btn + .btn {
        margin-left: 16px;
        background-color: #3ACE5A;
        border: 1px solid #2AB448;
        color: white;
        margin-right: 20px;
    }
}
