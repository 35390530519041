.fc-day-grid-container.fc-scroller {
    height: auto!important;
    overflow-y: auto;
}

// TOP BUTTON
.fc-toolbar-ltr {
  padding: 20px 20px 5px;
}

.fc-toolbar-chunk {
  & .fc-button-group {
    border: 1px solid #e4e6ef !important;
    border-radius: 8px !important;
    padding: 3px !important;
  }
}

.fc-button-primary {
  background-color: transparent !important;
  color: #4a5677 !important;
  border-width: 0 !important;
  border-radius: 5px !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  padding: 5px 15px !important;

  &.fc-prev-button {
    padding: 5px 10px !important;
  }

  &.fc-next-button {
    padding: 5px 9px 5px 10px !important;
  }

  &.fc-today-button {
    border: 1px solid #e4e6ef !important;
    padding: 8px 15px !important;
    border-radius: 8px !important;

    &:disabled {
      background-color: #3e97ff !important;
      color: #fff !important;
      opacity: 1 !important;
    }
  }

  &.fc-timeGridWeek-button {
    margin: 0 4px 0 3px !important;
  }

  &:hover {
    background-color: #00000028 !important;
  }

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &.fc-button-active {
    background-color: #3e97ff !important;
    color: #fff !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.fc-toolbar-title {
  text-transform: capitalize;
  @media (max-width: 765px) {
    padding: 8px 0;
  }
}

.fc-header-toolbar{
  @media (max-width: 765px) {
    display: flex;
    flex-direction: column;
  }
}
// MONTH TAB
.fc-daygrid-day-number {
  color: #4a5677;
  font-size: 20px;
  font-weight: 500;
  padding: 0 !important;
  margin: 5px 5px 5px 0 !important;
  padding: 0 4px !important;
}

.fc-day-today {
  background-color: #f6f7fb !important;

  .fc-daygrid-day-number {
    background-color: #3e97ff;
    border-radius: 8px;
    color: #fff;
  }
}

.fc-col-header-cell-cushion {
  color: #9ea5bd;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.fc-col-header-cell {
  margin: 0 !important;
  &.fc-day {
    border-left: 0;
    border-right: 0;
    .fc-scrollgrid-sync-inner {
      background-color: #f6f7fb !important;
      padding: 5px 0 !important;
    }
  }
}

// WEEK TAB
.fc-day-today {
  .fc-col-header-cell-cushion {
    background-color: #3e97ff !important;
    color: #fff !important;
    border-radius: 5px;
  }
}

// DAY TAB

// EVENT

.fc-event-time {
  color: #4a5677 !important;
  font-weight: 700;
}

.fc-event-title {
  color: #4a5677 !important;
  font-weight: 500;
}

.fc-timegrid-event,
.fc-daygrid-event {
  padding: 0 5px;
  font-size: 12px;
  background-color: #b5f3fb;
  border-width: 0 !important;
  border-left: 4px solid #22cce2 !important;
  overflow: hidden;
  &:hover{
    background-color: #92f2ff;
  }
}

.fc-daygrid-event-dot {
  display: none;
}

// DAY OF OTHER MONTH
.fc-day-other {
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 10px,
    #f8f8f8 10px,
    #f8f8f8 11.5px
  );
}

// TABLE
.fc-timegrid-slot-label {
  border-top: 0 !important;
}

.fc .fc-timegrid-slot-minor {
  border: 0 !important;
}

.fc-scrollgrid {
  border-left: none !important;
  border-bottom: none !important;
}

.fc-scrollgrid td:last-of-type {
  border-right: none !important;
  border-bottom: none !important;
}

tr:nth-child(1) th{
  .fc-timegrid-axis{
    background-color: #f6f7fb !important;
    border-right: none !important;
  }
}

.fc-timegrid-event .fc-event-main {
  padding: 0 !important;
}

.fc-more-link {
  text-transform: capitalize !important;
  color: #4A5677;
  font-size: 11px;
  font-weight: 600;
  @media (max-width: 445px) {
    font-size: 9px;
  }
}

.fc-popover-header {
  color: #4A5677;
  font-weight: 600;
}

.tooltip .tooltip-inner {
  box-shadow: none !important;
  padding: 0 !important;
  max-width: max-content !important;
  width: 100% !important;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

.tooltip-container {
  width: 460px;
  min-height: 160px;
  background-color: #FFF;
  padding: 20px;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
  color: #4A5677;

  @media (max-width: 500px)  {
    width: 360px;
  }
}