$darkBlue : #4A5677;
$grayBorder : #DBE3EF;
$lightGray : #9EA5BD;

.Tabs_Container {
    overflow-x:auto;
    overflow-y: hidden;
    .tabbed {
        border-bottom: 1px solid $grayBorder;
    }
    
}

.Tabs_Container::-webkit-scrollbar {
    display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Tabs_Container {
-ms-overflow-style: none !important;  /* IE and Edge */
scrollbar-width: none !important;  /* Firefox */
}

.tabbed .tab {
    background: #FCFCFC;
    padding: 0.7rem 2.4rem 0.7rem 2.4rem;
    position: relative;
    cursor: pointer;
    color: $lightGray;
    border-top: 1px solid #DBE3EF;
    border-left: 1px solid #DBE3EF;

    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;

    white-space: nowrap;
    margin-right: 0.7rem;

    transition: all 250ms ease;
    max-height: 45px;
}

.tabbed .tab:before {
    display: block;
    content: " ";
    position: absolute;
    top: -1px;
    bottom: -1px;
    width: 2.3rem;  
    background: #FCFCFC;


    right: -1rem;
    transform: skew(35deg, 0deg);
    border-right: 1px solid #DBE3EF;
    border-top: 1px solid #DBE3EF;
    border-bottom: 1px solid #DBE3EF;
    border-top-right-radius: 6px;
    transition: all 250ms ease;
}

.tabbed .tab:hover, .tabbed .tab:hover:before{
    color: #444;
}

.tabbed .tab.active, .tabbed .active:before{
    background-color: #ffffff;
    color: $darkBlue;
    z-index: 2;
    border-bottom: 0;
}

.tabbed .active:after{
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: calc(100% + 1.8rem );
    height: 2px;
    background: white;
}


/*----- Round Tabs -----*/
.tabbed.round .tab {
    border-radius: 6px 6px 0 0;
}

