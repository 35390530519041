.container {
    flex: 1;
}

.btn-download {
    background-color: #3ACE5A;
    width: 142px;
    height: 42px;
    border-radius: 6px;
    border: 0;
    color: white;
    font-weight: 600;
}

.content {
    background-color: #F6F7FB;
    display: flex;
    flex-direction: row;
    padding: 0 10px 20px 10px;
    justify-content: space-around;
    flex-wrap: wrap;
}

.loading {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    width: 290px;   
    height: auto;
    background-color: white;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 30px;
    border: 1px solid #DBE3EF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.header {
    width: "100%";
    height: 49px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    padding: 16px 20px;
}

.image {
    width: "100%";
    height: 160px;
    background-color: #F6F7FB;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DBE3EF;
    border-top: 1px solid #DBE3EF;
}

.border {
    width: 110px;
    height: 110px;
    border: 10px solid #F2F3F7;
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img {
    width: 58px;
    height: 58px;
}

.row {
    width: "100%";
    height: 93px;
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
}

.title {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: #4A5677;
}

.value {
    margin-top: 4px;
    font-size: 27px;
    font-weight: 800;
    line-height: 33px;
    color: #4A5677;
    display: flex;
    flex-direction: row;
}

.footer {
    width: "100%";
    height: 9px;
    background-color: #88CC1B;
}

.react-bootstrap-daterangepicker-container {
    width: 100% !important;
}

.toggle-button {
    height: calc(1.5em + 1.3rem + 2px);
    background-color: #FFFFFF;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;

    &:focus {
        border-color: #69b3ff !important;
        -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
}

