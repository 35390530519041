.login-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: url("../../../../assets/images/login_bg.svg") no-repeat top right;
    background-size: cover;

  header {
    width: 100%;
    //position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;

    .logo {
      margin-right: 16px;
    }

    .login-header-wrapper {
      padding: 16px 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
  }

  .login-title {
    text-transform: uppercase;
    font-weight: 500;
    color: #ffffff;
    font-size: 24px;
    line-height: 29px;
  }

  main {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-grow: 1;

    @media screen and (max-width: 992px) {
      justify-content: center;
    }
  }

  .login-form-divider {
    background: #e8e9eb;
    margin: 16px 0;
    height: 1px;
  }

  .login-config-button {
    display: inline-block;
    width: 100%;
    background: #e4e6ef;
    border-radius: 6px;
    border: none;
    line-height: 20px;
    padding: 10px 0;
    color: #4a5677;
    margin-bottom: 8px;
    font-weight: 500;
    &:hover{
      opacity: 0.9;
    }
  }


  .login-config-desc {
    color: #4a5677;
    text-align: center;
  }
}

.Footer {
  margin-top: auto;
}

@media only screen and (max-width: 1024px) {
  .login-sound {
    display: none;
  }
}
