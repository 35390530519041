.round-top {
    border-radius: 10px 10px 0 0;
}

.round-bottom {
    border-radius: 0 0 7px 7px;
}

.custom-search {
    width: 150px;
}

.CardButton {
    padding: 12px;
    gap: 8px;
    background: #3ACE5A;
    border-radius: 8px;
    color: white;
    border: none;

    i {
        color: white;
        font-size: 17.44px;
    }

    p {
        line-height: 15.73px;
    }
}