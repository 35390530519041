.table-responsive{
    height:200px;  
    width: 100%;
    overflow:scroll;
  }
    .table-responsive::-webkit-scrollbar {
        display: none;
    }
thead tr:nth-child(1) th{
    background: white;
    position: sticky;
    top: 0;
    z-index: 10;
}

.checkbox-disabled{
    pointer-events: none;
}