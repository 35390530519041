.modal {
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background: white;
        border-radius: 6px;
    }
      
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 6px;
    }
      
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
        border-radius: 6px;
    }
}

.title {
    height: 44px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #4A5677;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #DBE3EF;
}

.body {
    height: 400px;
    overflow-y: scroll;
}

.row {
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.left {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #4A5677;
    margin-left: 20px;
    margin-right: 2px;
}

.right {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #5180FB;
}

.devide {
    height: 1px;
    background-color: #DBE3EF;
    margin-left: 20px;
    margin-right: 20px;
}

.content {
    margin-left: 20px;
    margin-right: 20px;
    color: #4A5677;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.file-attach {
    height: 165px;
}

.label {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #4A5677;
    margin-left: 20px;
    margin-top: 10px;
}

.files {
    height: 120px;
    margin: 10px 20px 0px 20px !important;
    display: flex;
    flex-direction: row;
    width: 755px;
    overflow-x: auto;
    overflow-y: hidden;
}

@media only screen and (max-width: 992px) {
    .files {
        width: 455px;
    }
} 

.wrapper {
    height: 120px;
    width: 100px;
    position: relative;

    + .wrapper {
        margin-left: 10px;
    }
}

.file {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F6F7FB;
    border: 1px solid #DBE3EF;
    width: 100px;
    height: 100px;
}

.file-name {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4A5677;
    font-weight: 400;
    font-size: 13px;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.icon-file {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.area {
    height: 256px;
    border-top: 1px solid #E4E6EF;
    border-bottom: 1px solid #E4E6EF;
    background: #F6F7FB;
}

.border {
    height: 212px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 6px;
    border: 1px solid #DBE3EF;
    padding: 12px 12px 12px 16px;
    background-color: white;
}

.input {
    list-style: none;
    outline: none !important;
    border: none;
    background-color: transparent;
    width: 100%;
    height: 190px;
    resize: none;
}

.action {
    display: flex;
    flex-direction: row;
    height: 60px;
    border-top: 1px solid #DBE3EF;
    align-items: center;
    padding: 0 20px;
}

.btn {
    flex: 1;
    height: 40px;
    border: none;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    border-radius: 6px;
    border: 1px solid #DBE3EF;  
    color: #4A5677;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
    color: #4A5677;
    background-color: white;

    &:hover {
        background-color: #f3f3f3;
    }
}

.btn + .btn {
    margin-left: 16px;
    background-color: #5180FB;
    color: white;

    &:hover {
        opacity: 0.7;
    }
}

.disable {
    flex: 1;
    height: 40px;
    border: 1px solid #DBE3EF; 
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    border-radius: 6px;
    background-color: #20A144;
    opacity: 0.6;
    cursor: default !important;
    margin-left: 16px;
    color: white;

    &:hover {
        background-color: #5180FB;
    }
}

.input-file {
    opacity: 0;
    display: none;
}

.btn-attach {
    width: 70px;
    height: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    background-color: white;
    border-radius: 5px;
    border: 2px solid #959595;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: #f3f3f3;
    }
}

.icon-delete {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    cursor: pointer;
    color: black;
    background-color: white;
    border-radius: 8px;
    border: 1px solid white;

    &:hover {
        opacity: 0.6;
    }
}

