.custom-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    padding-left: 20px;
    font-size: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;


    input { 
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
     }

    .checkmark {
        position: absolute;
        top: -7px;
        left: 0px;
        height: 20px;
        width: 20px;
        border-radius: 6px;
        background-color: #EBEDF3;
      }

    &:hover input ~ .checkmark {
        background-color: #ccc;
    }

    input:checked ~ .checkmark {
        background-color: #2196F3;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        left: 8px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    &:disabled {
        background: #ccc;
    }
}