.login-form {
    width: 350px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 100%;
    padding: 16px;
}

.login-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: url("../../../../assets/images/login_bg.svg") no-repeat top right;
    background-size: cover;

    header {
        width: 100%;
        //position: sticky;
        top: 0;
        left: 0;
        z-index: 1000;

        .logo {
            margin-right: 16px;
        }

        .login-header-wrapper {
            padding: 16px 0;
            margin: 0 auto;
            display: flex;
            align-items: center;
        }
    }

    .login-title {
        text-transform: uppercase;
        font-weight: 500;
        color: #FFFFFF;
        font-size: 24px;
        line-height: 29px;
    }

    main {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-grow: 1;

        @media screen and (max-width: 992px) {
            justify-content: center;
        }
    }

    .cancel-button {
        display: inline-block;
        width: 100%;
        background: #E4E6EF;
        border-radius: 6px;
        border: none;
        line-height: 20px;
        padding: 10px 0;
        color: #4A5677;
        margin-bottom: 8px;
        font-weight: 500;
        &:hover{
            opacity: 0.9;
        }
    }

    .login-button {
        background-color: #3e97ff;
        width: 100%;
        border-radius: 6px;
        border: none;
        line-height: 20px;
        padding: 10px 0;
        color: white;
        margin-bottom: 3px;
        font-weight: 500;
        &:hover{
            opacity: 0.9;
        }
        &:disabled{
            background-color: #cccccc;
        }
    }
}

.Footer {
    margin-top: auto;
}

@media only screen and (max-width: 1024px) {
    .login-sound {
        display: none;
    }
}