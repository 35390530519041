.level-up {
  i {
    color: #3ACE5A;
    transform: rotate(90deg);
  }

  p {
    color: #3ACE5A !important;
  }
}

.sort-direction {
  p {
    position: relative;
    width: min-content;
  }

  i {
    position: absolute;
    left: calc(100% + 0.5rem);
  }
}

.bulletin-datatable {
  tbody {
    td:not(:last-child) {
      white-space: normal;
    }
  }


}

.bulletin-status-badge {
  border-radius: 4px;
  text-align: center;
  padding: 4px 12px;
  white-space: nowrap;

  &#{&}-playing {
    background: rgba(32, 161, 68, 0.1);
    color: #20A144;
  }

  &#{&}-played {
    background: rgba(229, 110, 25, 0.1);;
    color: #E56E19;
  }

  &#{&}-stopped {
    background: rgba(230, 65, 53, 0.1);
    color: #E54135;
  }

}
