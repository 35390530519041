.container {
    flex: 1;
    // overflow: hidden !important;
    // border-bottom-left-radius: 8px;
    // border-bottom-right-radius: 8px;
}

.table {
    min-height: 232px;
}

.area {
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    color: #4A5677;
    margin-right: 8px;
}

.status {
    display: flex;
    flex-direction: row;
    margin-left: 8px;
    margin-right: 16px;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon-sort {
    margin-left: 4px;
    font-size: 15px;
}

.status-item {
    width: 110px;
    height: 36px;
    border: none;
    margin-bottom: 3px;
    border-radius: 6px;
    background-color: white;
    border: 1px solid #DBE3EF;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: #4A5677;

    &:hover {
        border-color: #3699FF;
        cursor: pointer;
    }
}

.status-label {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: #4A5677;
}

.select {
    width: 120px;
    height: 40px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: #4A5677;
    border: 1px solid #DBE3EF;
    border-radius: 8px;
    background-color: white;
    margin-left: 8px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
}

.focus {
    border-color: #3699FF;
}

.icon-down {
    position: absolute;
    top: 10px;
    right: 10px;
}

.id {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: #3699FF;
}

.content {
    color: #4A5677;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    max-width: 440px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.answer {
    width: 75px;
    height: 40px;
    border: 1px solid #DBE3EF;
    color: #4A5677;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);

    &:hover {
        background-color: #f9f9f9;
    }
}

.share {
    width: 75px;
    height: 40px;
    border: 1px solid #DBE3EF;
    color: white;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    background-color: #5180FB;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);

    &:hover {
        opacity: 0.9;
    }
}

.wrapper {
    width: 110px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}

.icon {
    width: 12px;
    height: 13px;
    object-fit: contain;
    margin-right: 4px;
}

.label {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}