::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 8px;
    opacity: 0.5;
    //background-color: #F5F5F5;
}

::-webkit-scrollbar
{
    width: 12px;
    opacity: 0.6;
    //background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
    border-radius: 8px;
    color: gray;
    // opacity: 0.5;
    -webkit-box-shadow: inset 0 0 10px rgba(146, 140, 140, 0.87);
    //background-color: #555;
}