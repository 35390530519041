.labelField{
  font-weight: 600;
  font-size: 13px;
  color : #4A5677;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  text-align: right;
  padding-left: 0;

  @media (min-width: 768px) and (max-width: 1200px), (max-width: 576px) {
      text-align: left;
      justify-content: start;
      text-align: initial;
      margin-bottom: 5px;
  }
}

.textField {
  font-weight: 500;
  font-size: 13px;
  color: #4A5677;
  width: 100%;
  background-color: #F3F6F9;
  border-radius: 6px;
  height: 37px;
  line-height: 37px;
  vertical-align: middle;

  @media (min-width: 768px) and (max-width: 1200px), (max-width: 576px) {
      padding: 0 12.5px;
  }
}