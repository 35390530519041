.container {
    flex: 1;
    overflow: hidden !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.view {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 8px;
}

.delete-multi {
    width: 110px;
    height: 40px;
    background-color: #E64135;
    border: 1px solid #CD1F13;
    color: white;
    border-radius: 6px;
    margin-right: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    &:hover {
        opacity: 0.8;
    }
}

.label-1 {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #4A5677;
    display: block;
    margin-right: 8px;
}

.table {
    min-height: 232px;
}

.item-content {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #3699FF;
    flex: 1;
}       

.number {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #4A5677;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon-sort {
    margin-left: 4px;
    font-size: 15px;
}

.action {
    display: flex;
    flex-direction: row;
}

.icon {
    color: #B5B5C3;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        color: #5180FB;
    }
}

.icon + .icon {
    margin-left: 24px;

    &:hover {
        color:  #F64E60;
    }
} 
