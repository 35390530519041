.sidebar {
  // background-color: #419af8;
  //   background-color: "#5180FB";
  background-color: red;

  width: 24rem;
  border-radius: 0.8rem;
}
.aside {
  background-color: #5180fb;
}
.aside-menu .menu-nav {
  padding: 0;
}

.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: #3465e5;
}
.q-arrow::before {
  content: "\f105" !important;
  font-family: "Font Awesome 5 Pro" !important;
  font-size: 1rem !important;
}
