.progressbar {
  position: relative;
  overflow: hidden;
  width: 350px;
  height: 35px;
  border-radius: 5px;
  background-color: #eee;
}

.progressPercent {
  font-weight: 600;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #eee;
  text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
}
