.level-up {
    i {
        color: #3ACE5A;
        transform: rotate(90deg);
    }
    p {
        color: #3ACE5A !important;
    }
}

.sort-direction {
    p {
        position: relative;
        width: min-content;
    }
    i {
        position: absolute;
        left: calc(100% + 0.5rem);
    }
}