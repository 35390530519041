.round-top {
    border-radius: 10px 10px 0 0;
}


.CardButtonBlue {
    padding: 12px;
    gap: 8px;
    background: #5180FB;
    border-radius: 8px;
    color: white;
    border: none;
    border-color: #3465E5 !important;

    i {
        color: white;
        font-size: 17.44px;
    }

    p {
        line-height: 15.73px;
    }
    &:disabled{
        background :grey;
        border-color: grey !important;
    }
}

.CardButtonWhite {
    padding: 12px;
    gap: 8px;
    background: white;
    border-radius: 8px;
    color: #4A5677;

    i {
        color: white;
        font-size: 17.44px;
    }

    p {
        line-height: 15.73px;
    }
}