.custom-row-checkbox {
    i {
        color: #5180FB;
        font-size: 14px;
    } 
    
    p {
        color: #3E97FF;
    }
}

.status-box {
    border-radius: 4px;
    padding: 4px 9px 4px 9px;
}
